// routes
import { paths } from 'src/routes/paths';
import { UserRoles } from './constants';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.NEXT_PUBLIC_HOST_API;
export const ASSETS_API = process.env.NEXT_PUBLIC_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  callbackUrl: process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.NEXT_PUBLIC_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.auth.selectInstance; // as '/select-instance'
export const PATH_AFTER_SELECT_INSTANCE = (role) => {
  if (!role) return paths.dashboard.root;

  const roleGroups = {
    billing: [UserRoles.BILLING_ADMINISTRATOR, UserRoles.BILLING_STAFF],
    cp: [UserRoles.CP_ADMINISTRATOR, UserRoles.CP_OFFICE_USER, UserRoles.CP_STAFF],
    tech: [UserRoles.TECHNICIAN],
    sysAdmin: [UserRoles.SYSTEM_ADMINISTRATOR],
  };

  if (roleGroups.sysAdmin.includes(role)) {
    return paths.dashboard.laboratoryList;
  }
  // if (roleGroups.billing.includes(role)) {
  //   return paths.dashboard.billing;
  // }
  if (roleGroups.cp.includes(role)) {
    return paths.dashboard.caseList;
  }
  if (roleGroups.tech.includes(role)) {
    return paths.dashboard.modelReview;
  }
  return paths.dashboard.root;
};
